<template>
    <div class="footer">
        Desenvolvido utilizando Vue.js por David Chagas
    </div>    
</template>

<script>
    export default {
        name: 'Footer'
    }
</script>

<style lang="scss">
    .footer{
        padding: 10px 0;

        font-size: 12px;
        text-align: center;
        
        background-color: #dbd9d9;
        color: #696969;
    } 
</style>

